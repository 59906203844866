import React, { lazy } from "react";
const GoalsSettings = lazy(() => import("./components/GoalsSettings/GoalsSettings"));
const WSSettings = lazy(() => import("./components/WSSettings/WSSettings"));
const ReviewsSettings = lazy(() => import("./components/ReviewsSettings/ReviewsSettings"));
const SurveysSettings = lazy(() => import("./components/SurveysSettings/SurveysSettings"));
const CheckInsSettings = lazy(() => import("./components/CheckInsSettings/CheckInsSettings"));

export const modulesSettings = {
    Goals: {
        title: "goals",
        nestedMenu: [],
        get component() {
            return <GoalsSettings/>
        }
    },
    "Weekly status": {
        title: "weekly status",
        nestedMenu: [],
        activeSubItemIndex: 0,
        get component() {
            return <WSSettings/>
        }
    },
    Reviews: {
        title: "reviews",
        nestedMenu: ['General', 'Review period'],
        activeSubItemIndex: 0,
        get component() {
            return <ReviewsSettings activeIndex={this.activeSubItemIndex}/>
        }
    },
    Surveys: {
        title: "surveys",
        nestedMenu: ['General', 'Metrics', 'eNPS'],
        activeSubItemIndex: 0,
        get component() {
            return <SurveysSettings activeIndex={this.activeSubItemIndex} />
        }
    },
    "Check-ins": {
        title: "check-ins",
        nestedMenu: [],
        inactiveIndex: "true",
        get component() {
            return <CheckInsSettings/>
        }
    }
}

export function bbb(activeTab, index) {
    if (activeTab === "Reviews") {
        return <ReviewsSettings activeIndex={index}/>
    } else if (activeTab === "Weekly status") {
        return <WSSettings/>
    } else if (activeTab === "Goals") {
        return <GoalsSettings/>
    } else if (activeTab === "Surveys") {
        return <SurveysSettings activeIndex={index} />
    } else {
        return <CheckInsSettings/>
    }
}
