import request from "./utils/request";
import generateQueryStringArr from "../utils/generateQueryStringArr";

export const user = {
    user: () => {
        return request('GET', 'user');
    },
    users: (team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false) => {
        return request('GET', `users?id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}`);
    },
    getExistingUsers: (team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false) => {
        return request('GET', `users_filter_all?id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}`);
    },
    allUsers: (custom_fields = [], team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false, active = '') => {
        return request('GET', `users_all?custom_fields=${custom_fields}&id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}&active=${active}`);
    },
    addUser: (data, isMobile = false,  team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, active = '') => {
        return request('POST', `users?is_mobile=${+isMobile}&id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&active=${active}`, data);
    },
    editUser: (id, data, team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false, active = '') => {
        return request('PUT', `users/${id}?id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}&active=${active}`, data);
    },
    deleteUser: (id, team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false, active = '') => {
        return request('DELETE', `users/${id}?id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}&active=${active}`);
    },
    deleteUsers: (data = { delete_user_ids: [] }) => {
        return request('POST', 'user_bulk_delete', data);
    },
    changeUsersStatus: (data = { change_active_user_ids: [], active: 1 }) => {
        return request('POST', 'users/bulk_change_active', data);
    },
    getUserByID: (userID, permissionDenied = '') => {
        return request('GET', `users/${userID}?permission_denied=${permissionDenied}`);
    },
    getActiveUser: (id, permissionDenied = '') => {
        return request('GET', `users_active/${id}?permission_denied=${permissionDenied}`);
    },
    getSpecificUserActivity: (id, page = 1, permissionDenied = '') => {
        return request("GET", `users/${id}/activity?page=${page}&permission_denied=${permissionDenied}`);
    },
    addPostForSpecificUser: (id, data, assigneesIds = []) => {
        const idsQueryString = generateQueryStringArr(assigneesIds, 'id_apm_user');

        return request("POST", `users/${id}/post?${idsQueryString}`, data);
    },
    getPostForSpecificUser: (id, page = 1, permissionDenied = '') => {
        return request("GET", `users/${id}/posts?page=${page}&permission_denied=${permissionDenied}`);
    },
    deleteSpecificUserPost: (postId) => {
        return request("DELETE", `users/post/${postId}`);
    },
    editSpecificUserPost: (postId, data) => {
        return request("POST", `users/post/${postId}`, data);
    },
    getPostAttachments: (id, page = 1) => {
        return request('GET', `posts/${id}/attachments?page=${page}`);
    },
    editUserPassword: (id, data) => {
        return request('POST', `users/change_password_by_admin/${id}`, data);
    },
    resendUserEmailVerification: (id) => {
        return request('GET', `users/resend_email_confirmation/${id}`);
    },
    changeUserStatus: (id, data) => {
        return request('POST', `users/change_active/${id}`, data);
    },
    usersImport: (data) => {
        return request('POST', 'users_import', data);
    },
    getUsersForAssignment: (page = 1, name = '', role = '', team = '') => {
        return request("GET", `survey_goal_checkin/users?page=${page}&name=${name}&id_team=${team}&id_apm_acl_role=${role}`);
    },
    getDeletedUsers: (page = 1, isMobile = false) => {
        return request('GET', `deleted_users?page=${page}&is_mobile=${+isMobile}`);
    },
    recoverUser: (id, page = 1, isMobile = false) => {
        return request('GET', `users/recover_user/${id}?page=${page}&is_mobile=${+isMobile}`);
    },
    getUserReviews: (id, startDate = '', page = 1, groupType = 'peer') => {
        return request('GET', `user/reviews/${id}?start=${startDate}&page=${page}&group_type=${groupType}`);
    },
    updateUserTimezone: (data = { timezone: '+00:00' }) => {
        return request('POST', 'user_timezone', data);
    },
    loginFromPSP: (data, url = '') => {
        return request('POST', `https://${url}/login/login_from_psp`, data, '', false);
    },
    getSurveysGoalCheckinUsers: (name = '', page = 1) => {
        return request('GET', `survey_goal_checkin/users?name=${name}&page=${page}`);
    },
    getUsersFilter: (team = "", user = "", role = "", sortBy = "created_at", sortByType = "desc", page = 1, isModal = 0, isMobile = false) => {
        return request('GET', `users_filter?id_team=${team}&name=${user}&id_apm_acl_role=${role}&sort_by=${sortBy}&sort_by_type=${sortByType}&page=${page}&goal_modal=${isModal}&is_mobile=${+isMobile}`);
    },
};
