import axios from 'axios';
import useNotification from "Hooks/useNotification";

export default function request(method, url, data, params, pspApiUrl = true) {
    const errorNote = useNotification;
    const token = localStorage.getItem("auth_token");
    const apiUrl = process.env.valueOf().REACT_APP_API_URL;

    return axios({
        method: method,
        url: pspApiUrl ? apiUrl + url : url,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        data: data,
        params,
    }).then((response) => {
        return response;
    }).catch((error) => {
        if ((error?.response?.status === 300) || (error?.response?.status === 404)) {
            console.log(error.response);
            throw error;
        }

        if (error.response && error.response.data.message === 'Unauthenticated.') {
            localStorage.removeItem("auth_token");
            window.location.href = `${process.env.REACT_APP_URL_PREFIX}/login`;
        } else {
            let errorMessage;

            if(typeof error.response?.data?.error_message !== 'object') {
                errorMessage = error.response?.data?.error_message ?? error.response?.data?.message;
            } else {
                errorMessage = Object
                    .keys(error.response.data.error_message)
                    .map(key => error.response.data.error_message[key]).join(' ');
            }

            if (errorMessage === 'Server Error') {
                errorMessage = 'Something went wrong';
            }

            errorNote(
                "error",
                "Warning!",
                errorMessage
            )
            console.log((error.response && error?.response?.data?.errors?.name) || error?.response?.data?.message, "Error");
        }
    });
}
