import React from 'react';

// Styles
import './style/footer.scss';

const Footer = ({footerLogo}) => {
    return (
        <div className="footer_wrapper">
            <div className="footer_inner">
                <div className="footer_left">
                    <span className="text">Powered by</span>
                    <a href="https://performance.eleapsoftware.com" target="_blank">
                        <span className="icon"><img loading="lazy" src={footerLogo} alt="logo" width="60" height="32.73" /></span>
                    </a>
                </div>
                <div className="footer_right">
                    <div className="top_info">
                        Copyright 2005 - {new Date().getFullYear()} Telania, LLC. All rights reserved.
                    </div>
                    <div className="bottom_info">
                        Unauthorized use or reproduction is prohibited by US copyright laws and international treaties.&nbsp;
                        <a href="https://performance.eleapsoftware.com/psp-privacy-policy/" target="_blank">
                            Privacy policy
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Footer);